// -----------------------------------------------------------------------------
// This file contains styles that are specific to the legal page.
// -----------------------------------------------------------------------------
.page-politica-sistema-gestion {
  .highlighted {
    margin: 2rem;
    .item {
      margin: 0 1rem;
      padding: 1rem;
      background: $color-dark;
      border-radius: 2rem;
      color: #FFF;
      border: 5px solid $color-primary-light;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

